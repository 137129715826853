<template>
  <div class="cycle">
    <div id="brand-circle">
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CorpCircle",
};
</script>

<style scoped>
#brand-circle {
  width: 100%;
  aspect-ratio: 40/33;
  margin: 13px auto 0;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.21);
  display: flex;
  justify-content: center;
  align-items: center;
}
#brand-circle > div {
  width: 33.3%;
  aspect-ratio: 6 / 5;
  border-radius: 50%;
  background: rgba(226, 238, 255, 1);
  opacity: 1;
  z-index: 1;
}


</style>