<template>
  <div class="header-wrap">
    <div class="header">
      <div class="language-wrap">
        <Language class="language" :language="language" />
      </div>
      <div class="logo-nav-wrap">
        <img id="logo" alt="Vue logo" src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/beney_logo.png" />
        <div id="logo-nav-group">
          <Navigator :language="language" class="nav" />
          <SNS class="sns" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navigator from "@/components/Navigator.vue";
import Language from "@/components/Language.vue";
import SNS from "@/components/SNS.vue";
export default {
  name: "Header",
  components: {
    Navigator,
    Language,
    SNS,
  },
  props: {
    language: String,
  },
};
</script>

<style scoped="">
.header-wrap {
  width: 100%;
  height: 120px;
}
.header {
  width: 100%;
  min-width: 1280px;
  height: 120px;
  padding: 22px 48px 28px;
  position: fixed;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}
.language-wrap {
  height: 14px;
  text-align: right;
}
.logo-nav-wrap {
  height: 50px;
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
#logo {
  width: 163px;
  height: 38px;
}
#logo-nav-group {
  width: 46%;
  min-width: 760px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 10px;
}
.sns {
  height: 18px;
}
</style>