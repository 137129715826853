<template>
  <div class="home">
    <div id="first">
      <Header :language="language" />
    </div>
    <Carousel :language="language" id="carousel" />

    <div id="second">
      <Concept02 :language="language" />
    </div>
    <div id="third">
      <Concept03 :scrollTopNum="scrollTopNum" :language="language" />
    </div>
    <div id="solution">
      <SolutionBanner :language="language" />
    </div>
    <CorpBrand :scrollTopNum="scrollTopNum" :language="language" />
    <div id="fourth">
      <NewsCenter :language="language" />
    </div>
    <Footer :language="language" />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Carousel from "@/components/Home/Carousel.vue";
import Concept02 from "@/components/Home/Concept02.vue";
import Concept03 from "@/components/Home/Concept03.vue";
import SolutionBanner from "@/components/Home/SolutionBanner.vue";
import CorpBrand from "@/components/Home/CorpBrand.vue";
import NewsCenter from "@/components/Home/NewsCenter.vue";
import Footer from "@/components/Footer.vue";
import { getCountry } from "@/api/country.js";
import storage from "@/utils/stored-data.js";

export default {
  name: "Home",
  components: {
    Header,
    Carousel,
    Concept02,
    Concept03,
    SolutionBanner,
    CorpBrand,
    NewsCenter,
    Footer,
  },
  data() {
    return {
      scrollTopNum: 0, // 页面当前高度
      // 新闻分页
      pagination: {
        pageSize: 8,
        current: 1,
        total: 0,
      },
      language: "zh",
    };
  },
  methods: {
    // 处理画面滚动事件
    handleScroll() {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      this.scrollTopNum = top;
    },
    _getlanguage() {
      getCountry()
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.data.country == "CN") {
              // 统一保存7天
              storage.set("language", "zh", 7 * 24 * 60);
              this.language = "zh";
            } else if (res.data.data.country == "JP") {
              storage.set("language", "jp", 7 * 24 * 60);
              this.language = "jp";
            } else {
              // 如果用户所在国家不是中国或日本，则将语言设置为英语
              storage.set("language", "eng", 7 * 24 * 60);
              this.language = "eng";
            }
          }
        })
        .catch((err) => {
          // 如果出错，则将用户语言设置为zh，目前后端不会返回错误
          storage.set("language", "zh", 7 * 24 * 60);
        });
    },
  },
  mounted() {
    // 添加事件监听
    window.addEventListener("scroll", this.handleScroll, true);
    document.documentElement.scrollTop = 0;
    // 全局事件总线
    this.$bus.$on("language", (language) => {
      this.language = language;
    });
  },
  created() {
    // 如果没有保存用户的语言设置，则根据客户端所在国家/地区来自动设置语言
    if (!storage.get("language")) {
      this._getlanguage();
    } else {
      this.language = storage.get("language");
    }
  },
  destroyed() {
    // 销毁事件监听
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style scoped>
.home {
  width: 100%;
  min-width: 1280px;
}

#second {
  background: no-repeat 63.34% -92px / 35.78% url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/bannerBg.png"),
    no-repeat -7.5% 753px / 22.39% url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/bannerBg.png"),
    no-repeat 65.49% 782px / 21.7%
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/bannerBg.png");
}
#third {
  width: 100%;
  aspect-ratio: 1920 / 950;
}
#solution {
  width: 100%;
  aspect-ratio: 1920 / 894;
  background: no-repeat 28.59% 8px / 21.72%
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/bannerBg.png"),
    no-repeat 107% 309px / 22.76%
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/bannerBg.png");
}
#fourth {
  width: 100%;
  aspect-ratio: 1920 / 1064;
}
</style>