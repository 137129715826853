<template>
  <div class="container" ref="banner">
    <div class="body">
      <div class="text-item" id="body-row-1">
        <div class="number value">{{ jpStyleVal }}</div>
        <div class="text">%</div>
        <div class="title">
          <span v-if="language == 'zh'"> 原本日式风格</span>
          <span v-if="language == 'jp'"> 和風</span>
          <span v-if="language == 'eng'"> Goods from Japan</span>
        </div>
      </div>
      <div class="text-item" id="body-row-2">
        <div id="middle-group-1" class="middle-group">
          <div class="number">{{ jpBrandVal }}</div>
          <div class="text" v-if="language == 'zh'">家</div>
          <div class="title">
            <span v-if="language == 'zh'"> 知名日本品牌入驻</span>
            <span v-if="language == 'jp'"> ブランド出店</span>
            <span v-if="language == 'eng'"> Famous Brands from Japan</span>
          </div>
        </div>
        <div id="middle-group-2" class="middle-group">
          <div class="number">{{ jpCompanyVal }}</div>
          <div class="text" v-if="language == 'zh'">家</div>
          <div class="title">
            <span v-if="language == 'zh'"> 知名日本企业入驻</span>
            <span v-if="language == 'jp'"> 協力事業体</span>
            <span v-if="language == 'eng'"> Famous companies from Japan</span>
          </div>
        </div>
      </div>
      <div class="text-item" id="body-row-3">
        <div class="number">{{ skuVal }}</div>
        <div class="text" v-if="language == 'zh'">个</div>
        <div class="title">
          <span v-if="language == 'zh'"> SKU</span>
          <span v-if="language == 'jp'"> アイテム</span>
          <span v-if="language == 'eng'">SKUs</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Concept03",
  props: {
    scrollTopNum: {
      type: Number,
      required: true,
    },
    language: String,
  },
  data() {
    return {
      tabshow: false, //是否进行某种操作
      jpStyleMax: 100,
      jpBrandMax: 100,
      jpCompanyMax: 70,
      skuMax: 1000,
      jpStyleVal: 0,
      jpBrandVal: 0,
      jpCompanyVal: 0,
      skuVal: 0,
      timer: "",
    };
  },

  watch: {
    scrollTopNum: function () {
      let offsetTop = this.$refs.banner.offsetTop;
      // 120为header高度
      if (this.scrollTopNum > offsetTop - 120 && !this.timer) {
        this.play();
      }
    },
  },

  methods: {
    play: function () {
      let jpStyleFlag = false;
      let jpBrandFlag = false;
      let jpCompanyFlag = false;
      let skuFlag = false;
      this.timer = setInterval(() => {
        if (jpStyleFlag && jpBrandFlag && jpCompanyFlag && skuFlag) {
          clearInterval(this.timer);
        }

        if (this.jpStyleVal >= this.jpStyleMax) {
          this.jpStyleVal = this.jpStyleMax;
          jpStyleFlag = true;
        } else {
          this.jpStyleVal += 3;
        }

        if (this.jpBrandVal >= this.jpBrandMax) {
          this.jpBrandVal = this.jpBrandMax;
          jpBrandFlag = true;
        } else {
          this.jpBrandVal += 3;
        }

        if (this.jpCompanyVal >= this.jpCompanyMax) {
          this.jpCompanyVal = this.jpCompanyMax;
          jpCompanyFlag = true;
        } else {
          this.jpCompanyVal += 3;
        }

        if (this.skuVal >= this.skuMax) {
          this.skuVal = this.skuMax;
          skuFlag = true;
        } else {
          this.skuVal += 20;
        }
      }, 20);
    },
  },
};
</script>

<style scoped lang= "scss">
.container {
  width: 100%;
  aspect-ratio: 1920 / 950;
  background: no-repeat center
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/important_number.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
.body {
  width: 1024px;
  height: 530px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;
}


#body-row-1 {
  width: 440px;
  height: 120px;
  margin-left: 256px;
  display: flex;
  justify-content: space-between;

  & > .number {
    width: 192px;
  }

  & > .text {
    font-size: 110px;
  }
}
#body-row-2 {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: space-between;
}

#middle-group-1 {
  width: 440px;
  height: 120px;
  display: flex;
  /* justify-content: space-between; */
  & > div {
    margin-left: 12px;
  }

  & > .number {
    width: 192px;
  }

  & > .title {
    width: 190px;
  }
}
#middle-group-2 {
  width: 400px;
  height: 120px;
  display: flex;

  & > div {
    margin-left: 12px;
  }

  & > .number {
    width: 128px;
  }

  & > .title {
    width: 210px;
  }
}

#body-row-3 {
  width: 465px;
  height: 120px;
  margin-left: 405px;
  display: flex;

  & > .number {
    width: 260px;
  }

  & > div {
    margin-left: 12px;
  }
}
.title {
  width: 125px;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4f4f51;
  margin-top: 40px;
}
.number {
  font-size: 120px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #242424;
}
.text {
  font-size: 100px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #242424;
  margin-top: 10px;
}
</style>