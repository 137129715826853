<template>
  <div id="main">
    <div class="up-arrow-wrap">
      <a-icon type="caret-up" @click="toTop" class="up-arrow" />
    </div>
    <div class="footer">
      <div id="group-1">
        <div id="group-1-title">
          <span v-if="language == 'zh'">相关链接</span>
          <span v-if="language == 'jp'">関連リンク</span>
          <span v-if="language == 'eng'">Related Links</span>
        </div>
        <div id="dropdown-wrap">
          <a-dropdown>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="1" id="kpis-link">
                <a-icon type="" />
                <a href="https://kpis.jp">
                  <span v-if="language != 'jp'">Kpis Solutions</span>
                  <span v-else>KPIソリュー ション</span>
                </a>
              </a-menu-item>
              <a-menu-item key="2">
                <a-icon type="" />
                <span v-if="language == 'zh'">贝尼商城</span>
                <span v-if="language == 'jp'">ベニーモール</span>
                <span v-if="language == 'eng'">Beney Mall</span>
              </a-menu-item>
            </a-menu>
            <a-button id="button-text">
              日本Beney <a-icon type="caret-down" />
            </a-button>
          </a-dropdown>
        </div>
      </div>
      <div id="group-2">
        <div id="group-2-wrap">
          <div @click="go('About', 'about')">
            <span v-if="language == 'zh'" class="zh-text">关于我们</span>
            <span v-if="language == 'jp'" class="jp-text">私たちについて</span>
            <span v-if="language == 'eng'" class="eng-text">About</span>
          </div>
          <div @click="go('About', 'contact')">
            <span v-if="language == 'zh'" class="zh-text">联系我们</span>
            <span v-if="language == 'jp'" class="jp-text">お問い合わせ</span>
            <span v-if="language == 'eng'" class="eng-text">Contact</span>
          </div>
          <div @click="read('Agreement')">
            <span v-if="language == 'zh'" class="zh-text">用户协议</span>
            <span v-if="language == 'jp'" class="jp-text">利用規約</span>
            <span v-if="language == 'eng'" class="eng-text">Terms of Use</span>
          </div>
          <div @click="read('Privacy')">
            <span v-if="language == 'zh'" class="zh-text">隐私政策</span>
            <span v-if="language == 'jp'" class="jp-text"
              >プライバシーポリシー</span
            >
            <span v-if="language == 'eng'" class="eng-text"
              >Privacy Policy</span
            >
          </div>
        </div>
      </div>
      <div id="group-3">
        <div id="group-3-wrap">
          <div id="tel">
            <span v-if="language == 'zh'">客服热线</span
            ><span v-if="language == 'jp'"
              >カスタマーサービスメールアドレス</span
            >
            <span v-if="language == 'eng'">TEL</span> : 021-62567898
          </div>
          <div id="email">
            <span v-if="language == 'zh'">客服邮箱</span
            ><span v-if="language == 'jp'">カスタマーサービスホットライン</span
            ><span v-if="language == 'eng'">E-mail</span> : Jia.Wei@beney.com
          </div>
          <SNS />
        </div>
      </div>
    </div>
    <div id="footer">
      <div id="content-wrap">
        <a-divider id="footer-divider" />
        <div class="footer-content">
          <div id="gov-record">
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019374"
              style="
                display: inline-block;
                text-decoration: none;
                height: 20px;
                line-height: 20px;
              "
              ><img src="../assets/beian.png" style="float: left" />
              <p
                style="
                  float: left;
                  height: 20px;
                  line-height: 20px;
                  margin: 0px 0px 0px 5px;
                  color: #939393;
                "
              >
                沪公网安备 31011502019374号
              </p>
            </a>

            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style="color: #939393"
              >沪ICP证 2022018972号</a
            >
            <div
              @click="go('License', 'license')"
              style="color: #939393; cursor: pointer"
            >
              经营者信息
            </div>
          </div>
        </div>
        <div class="footer-content">
          <span v-if="language == 'zh'"
            >Copyright&nbsp;©&nbsp;2022-20—&nbsp;贝尼云（上海）信息科技有限公司&nbsp;版权所有</span
          >
          <span v-if="language == 'jp'"
            >Copyright © 2022 Shanghai Beney E-commerce Co., Ltd. All rights
            reserved.</span
          >
          <span v-if="language == 'eng'"
            >Copyright © 2022 Shanghai Beney E-commerce Co., Ltd. All rights
            reserved.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SNS from "@/components/SNS.vue";
export default {
  name: "Footer",
  components: {
    SNS,
  },
  props: {
    contactTop: { type: Number, required: false },
    aboutTop: { type: Number, required: false },
    language: String,
  },

  data() {
    return {
      current: [this.$route.name],
    };
  },
  methods: {
    handleMenuClick(e) {
      // console.log("click left button", e);
    },
    toTop() {
      let top = document.documentElement.scrollTop;

      const scrollToTop = setInterval(() => {
        document.documentElement.scrollTop = top -= 100;

        if (top <= 0) {
          clearInterval(scrollToTop);
        }
      }, 10);
    },

    //页面跳转
    go(name, target) {
      if (this.current[0] != name) {
        this.$router.push({ name, query: { target } });
      } else if (this.current[0] == "About") {
        // 如果当前页面是about页面，则回滚到关于我们或者联系我们页面
        if (target == "about") {
          this.scrollTo(this.aboutTop);
        } else if (target == "contact") {
          this.scrollTo(this.contactTop);
        }
      }
    },
    read(name) {
      this.$router.push({ name });
    },
    // 页面回滚
    scrollTo(target) {
      let top = document.documentElement.scrollTop;

      const scrollToTimer = setInterval(() => {
        document.documentElement.scrollTop = top -= 100;
        // 120为header高度
        if (top < target - 120) {
          clearInterval(scrollToTimer);
          document.documentElement.scrollTop = target - 120;
        }
      }, 10);
    },
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  aspect-ratio: 1920 / 757;
  position: relative;
  background-color: #ffffff;
  /**1920P:757P */
}
.up-arrow {
  &-wrap {
    text-align: right;
    height: 0.6%;
    margin-top: 7%;
    margin-right: 17%;
    color: #929293;
  }

  &:hover {
    color: rgba(1, 128, 205, 1);
  }
}
.footer {
  // width: 1280px;
  width: 80%;
  min-width: 960px;
  height: 30%; /**1920P:204.39p */
  display: flex;
  justify-content: space-between;
  margin: 72px auto 0;
  padding: 0 60px;
}
#group-1 {
  height: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
#group-1-title {
  height: 18px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(36, 36, 36, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 18px;
  text-align: left;
}
#dropdown-wrap {
  height: 46.5%;
}
#kpis-link > a {
  display: inline;
}
#button-text {
  font-size: 9px;
  color: #929293;
  text-align: left;
  padding-left: 11px;
}
.ant-btn {
  width: 205px;
  height: 100%;
}
.ant-btn > i {
  position: absolute;
  right: 6px;
  top: 7px;
}
.ant-btn:hover,
.ant-btn:focus {
  border-color: #0180cd;
}
#group-2 {
  height: 100%;
  position: relative;
  right: 10%;
  margin-left: 3%;
}
#group-2-wrap {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  & > div {
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    text-align: left;
  }

  & > div:hover {
    color: #1890ff;
  }
}

#group-3 {
  height: 81.22%; /**1920p:166p */
  margin-top: 0.208%;
  text-align: left;
}
#group-3-wrap {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#tel {
  margin-bottom: 9.72%;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  line-height: 14px;
}
#email {
  margin-bottom: 114px;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  line-height: 14px;
}
#footer {
  width: 66.5%; /**892.8p */
  height: 9.3%;
  position: absolute;
  bottom: 6%;
  left: 50%;
  transform: translate(-50%);
}
#footer-divider {
  margin-top: 0px;
}

#footer-content-1 {
  margin-bottom: 8px;
}

#gov-record {
  color: #939393;
  display: flex;
  width: 600px;
  margin: 0 auto;
  justify-content: center;

  & > a {
    margin: 0 10px;
  }
}
/* 
@media screen and (max-width: 1680px) {
  #group-2-wrap > div,
  #group-1-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  #tel,
  #email {
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 12px;
  }
}

@media screen and (max-width: 1600px) {
  #group-2-wrap > div,
  #group-1-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  #tel,
  #email {
    height: 12px;
    font-size: 12px;
    line-height: 12px;
  }
}

@media screen and (max-width: 1440px) {
  #group-2-wrap > div,
  #group-1-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  #tel,
  #email {
    height: 11px;
    font-size: 11px;
    line-height: 11px;
  }
}

@media screen and (max-width: 1366px) {
  #group-2-wrap > div,
  #group-1-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  #tel,
  #email {
    height: 10px;
    font-size: 10px;
    line-height: 10px;
  }
}

@media screen and (max-width: 1280px) {
  #group-2-wrap > div,
  #group-1-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  #tel,
  #email {
    height: 9px;
    font-size: 9px;
    line-height: 9px;
  }
} */
</style>