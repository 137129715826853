let routes = [
    {
        path: '/admin/login',
        name: 'AdminLogin',
        mode: 'history',
        component: () =>
            import('../views/admin/Login.vue'),
    },
    {
        path: '/admin',
        name: 'Admin',
        meta: { admin: true },
        component: () =>
            import("../views/admin/AdminHome.vue"),

        children: [
            {
                path: '/admin/admin-info',
                name: "AdminInfo",
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminInfo.vue'),
            },
            {
                path: '/admin/admin-news',
                name: "AdminNews",
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminNews.vue')
            },
            {
                path: '/admin/edit-news/:id',
                name: 'EditNews',
                meta: { admin: true },
                component: () =>
                    import("../views/admin/EditNews.vue")
            },
            {
                path: '/admin/news/:id',
                name: 'ShowNews',
                meta: { admin: true },
                component: () =>
                    import("../views/admin/AdminShowNews.vue")
            },
            {
                path: '/admin/recruitment',
                name: 'AdminRecruitment',
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminRecruitment.vue'),
            },
            {
                path: '/admin/announce',
                name: 'AdminAnnounce',
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminAnnounce.vue'),
            },
            {
                path: '/admin/carousel',
                name: 'AdminCarousel',
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminCarousel.vue'),
            },
            {
                path: '/admin/contact',
                name: 'AdminContact',
                meta: { admin: true },
                component: () =>
                    import('../views/admin/AdminContact.vue'),
            },
        ]
    }
]

export default routes;