<template>
  <div class="concept02">
    <div class="concept-container">
      <div class="introduction-title">
        <span v-if="language == 'zh'">种草赚钱</span
        ><span v-if="language == 'jp'">投稿が価値になる</span
        ><span v-if="language == 'eng'">Benefit</span>
      </div>
      <div class="introduction-content">
        <span v-if="language == 'zh'"
          >种草赚钱&nbsp;:&nbsp;每个消费者都可以发布种草笔记成为种草官。种草官通过图文或者视频的形式分享自己真实的购物体验，种草官带货成功就可以赚钱。不用再仰望KOL，在贝尼U品，人人都可以是KOL。</span
        >
        <span v-if="language == 'jp'"
          >Beney&nbsp;Mallで買い物したら誰でも投稿し、ベネフィッターになれる。ベネフィッターは写真あるいはショート動画で高品質商品を紹介し、見たユーザーが投稿の商品リンクから購入するとベネフィッターがお金もらえる。Beney&nbsp;Mallでは、KOLを憧れる必要なく、誰でもKOLになれる。</span
        >
        <span v-if="language == 'eng'"
          >Users who shopped on Beney Mall can share experiences on use of the
          product by pictures or videos, and these users are
          benefiters.Benefiters can earn money if other users bought the
          product. At Beney Mall, everyone can become a benefiter and a Key
          Opinion Leader.</span
        >
      </div>
      <div class="concept-body">
        <div class="gif-group-1">
          <div
            id="value-item-wrap"
            @mouseover="startPlay(gifObjects[0])"
            @mouseleave="stopPlay(gifObjects[0])"
          >
            <div
              ref="value"
              id="value-item"
              :style="{
                'background-position-y': ' ' + gifObjects[0].position_y + 'px',
              }"
            ></div>
          </div>
          <div
            ref="strawberry"
            id="strawberry-item-wrap"
            @mouseover="startPlay(gifObjects[1])"
            @mouseleave="stopPlay(gifObjects[1])"
          >
            <div
              id="strawberry-item"
              index="1"
              :style="{
                'background-position-y': ' ' + gifObjects[1].position_y + 'px',
              }"
            ></div>
          </div>
        </div>
        <div class="slogan-wrap">
          <div class="slogan">
            <span>
              <span v-if="language == 'zh'" class="zh-font">种草赚钱</span>
              <span v-if="language == 'jp'" class="jp-font"
                >投稿が価値になる</span
              >
              <span v-if="language == 'eng'" class="eng-font">Benefit</span>
            </span>
            <span>
              <span v-if="language == 'zh'" class="zh-font">真实社区</span>
              <span v-if="language == 'jp'" class="jp-font">リアルな投稿</span>
              <span v-if="language == 'eng'" class="eng-font"
                >Real Experience Reviews</span
              >
            </span>
            <span>
              <span v-if="language == 'zh'" class="zh-font">购物省钱</span>
              <span v-if="language == 'jp'" class="jp-font"
                >節約できる買い物へ</span
              >
              <span v-if="language == 'eng'" class="eng-font">Spend Less</span>
            </span>
            <span>
              <span v-if="language == 'zh'" class="zh-font">日本直邮</span>
              <span v-if="language == 'jp'" class="jp-font">日本直送</span>
              <span v-if="language == 'eng'" class="eng-font"
                >Delivered Directly from Japan</span
              >
            </span>
          </div>
        </div>
        <div class="gif-group-2">
          <div
            id="savemoney-item-wrap"
            @mouseover="startPlay(gifObjects[2])"
            @mouseleave="stopPlay(gifObjects[2])"
          >
            <div
              ref="savemoney"
              id="savemoney-item"
              index="2"
              :style="{
                'background-position-y': ' ' + gifObjects[2].position_y + 'px',
              }"
            ></div>
          </div>
          <div class="svg3-content small-font-size">
            <span v-if="language == 'zh'"
              >购物省钱&nbsp;:&nbsp;下单立减，高性价比入手进口商品，轻松过上精致生活。</span
            >
            <span v-if="language == 'jp'"
              >節約できる買い物へ&nbsp;:&nbsp;ポイ活で買うたびに安くできる、輸入商品を高いコスパで手に入れるため、高品質な生活へ。</span
            >
            <span v-if="language == 'eng'"
              >Spend Less:
              <br />
              You can spend less, and get high-quality goods from Japan at Beney
              Mall.</span
            >
          </div>
        </div>
      </div>
      <div id="text-group">
        <div id="text1" class="small-font-size">
          <span v-if="language == 'zh'"
            >真实社区&nbsp;:&nbsp;只有在贝尼U品购买过商品的用户才可以发布种草笔记，贝尼U品拒绝为了利益虚假安利的行为，只向用户展现真实的口碑。</span
          >
          <span v-if="language == 'jp'"
            >リアルな投稿&nbsp;:&nbsp;Beney&nbsp;Mallで買い物したことあるユーザーのみ投稿できる。Beney&nbsp;Mallは利益のための桜行為を断然お断り致します。</span
          >
          <span v-if="language == 'eng'"
            >Real Experience Reviews&nbsp;:&nbsp;
            <br />
            Users cannot post up if they did not buy the products, because we
            show the real reviews only and reject false experience reviews.
          </span>
        </div>
        <div id="text2" class="small-font-size">
          <span v-if="language == 'zh'"
            >日本直邮&nbsp;:&nbsp;所有商品皆为日本直邮，正品保障。</span
          >
          <span v-if="language == 'jp'">
            日本直送&nbsp;:
            すべての商品は日本直送で本物保証。</span
          >
          <span v-if="language == 'eng'"
            >Delivered Directly from Japan&nbsp;:&nbsp;
            <br />
            All the genuine goods are delivered directly from Japan.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Concept02",
  props: {
    language: String,
  },
  data() {
    return {
      gifObjects: [
        {
          position_y: 0,
          height: 230,
          totalFrames: 38,
          tik: 60,
          play: false,
        },
        {
          position_y: 0,
          height: 40,
          totalFrames: 18,
          play: false,
          tik: 60,
          ref: "strawberry",
        },
        {
          position_y: 0,
          height: 200,
          play: false,
          totalFrames: 49,
          ref: "savemoney",
          tik: 100,
        },
      ],
    };
  },
  methods: {
    startPlay(gifObjects) {
      gifObjects.play = true;
      this._playNext(gifObjects);
    },
    stopPlay(gifObjects) {
      gifObjects.play = false;
      this._playBack(gifObjects);
    },
    _playNext(gifObjects) {
      if (
        gifObjects.play &&
        gifObjects.position_y >
          (gifObjects.totalFrames - 1) * -gifObjects.height
      ) {
        gifObjects.position_y = gifObjects.position_y - gifObjects.height;
        setTimeout(() => {
          this._playNext(gifObjects);
        }, gifObjects.tik);
      }
    },
    _playBack(gifObjects) {
      if (!gifObjects.play && gifObjects.position_y < 0) {
        gifObjects.position_y = gifObjects.position_y + gifObjects.height;
        setTimeout(() => {
          this._playBack(gifObjects);
        }, gifObjects.tik);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.concept02 {
  width: 100%;
  min-width: 1280px;
  aspect-ratio: 1920 / 1278;
  /* border: 1px solid red; */
}
.concept {
  &-container {
    width: 75%; /* 1920px:1409.28px*/
    min-width: 1280px;
    height: 1349px;
    margin: 0 auto;
    padding-left: 3.28%;
  }

  /* border: 1px solid pink; */

  &-body {
    margin-top: 4.541%;
    width: 100%; /**1920p:625.26p */
    height: 50%;
    display: flex;
    justify-content: space-around;
  }
}
.introduction-title {
  margin-top: 120px;
  color: rgba(36, 36, 36, 1);
  font-size: 38px;
  font-family: PingFangSC-Medium;
  white-space: nowrap;
  line-height: 38px;
  text-align: justify;
}
.introduction-content {
  margin-top: 33px;
  width: 769px;
  overflow-wrap: break-word;
  color: rgba(79, 79, 81, 1);
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  text-align: justify;
  text-overflow: ellipsis;
}

.gif-group-1 {
  float: left;
  width: 480px;
  height: 627px;
  /* border: 1px solid green; */
}
#value-item-wrap {
  margin-top: 0.993%;
  min-width: 190px;
  min-height: 230px;
  width: 100%;
  height: 331px;
  margin-bottom: 3.335%;
  background: #f8b52d;
  display: flex;
  justify-content: center;
  align-items: center;
}
#value-item {
  width: 190px;
  height: 230px;
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/value_gif.png");
  background-size: 100%;
}
#strawberry-item-wrap {
  width: 268px;
  height: 278px;
  margin-left: 45%;
  padding: 0 5%;
  background: no-repeat center
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/comment_bg_concept.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
#strawberry-item {
  height: 40px;
  aspect-ratio: 128 / 27;
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/comment_gif.png");
  background-size: 100%;
}
.slogan {
  &-wrap {
    height: 627px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  margin-left: 3%;
}
// .slogan-wrap > span:nth-child(-n + 3) {
//   display: block;
//   margin-bottom: 10px;
// }
.gif-group-2 {
  width: 289px;
  height: 456px;
}
#savemoney-item-wrap {
  width: 289px;
  height: 456px;
  background: no-repeat url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/savemoney_bg_concept_home.png");
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}
#savemoney-item {
  height: 200px;
  aspect-ratio: 5 / 6;
  background: no-repeat url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/savemoney_gif.png");
  background-size: 100%;
}
.svg3-content {
  margin-top: 24px;
  color: rgba(79, 79, 81, 1);
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
}

#text-group {
  width: 530px;
  height: 100px;
  text-align: left;
  margin-left: calc(2% + 480px);
  display: flex;
  justify-content: space-between;
}
#text1 {
  float: left;
  width: 253px;
  height: 100px;
  overflow-wrap: break-word;
  color: rgba(79, 79, 81, 1);
  font-size: 14px;
  line-height: 26px;
  /* text-align: justify; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
  /* border: 1px solid purple; */
}
#text2 {
  float: left;
  margin-left: 1.5%;
  width: 245px;
  height: 100px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4f4f51;
  line-height: 26px;
}
.zh-font {
  font-size: 80px;
  display: inline-block;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #242424;
  line-height: 1.2;
}
.eng-font {
  display: inline-block;
  font-size: 60px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #242424;
  line-height: 1.2;
}
.jp-font {
  display: inline-block;
  font-size: 70px;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #242424;
  line-height: 1.2;
}
/* 
@media screen and (max-width: 1680px) {
  .slogan-wrap {
    width: 350px;
    height: 88px;
    font-size: 88px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #242424;
    line-height: 88px;
  }
}

@media screen and (max-width: 1600px) {
  .slogan-wrap {
    width: 333px;
    height: 83px;
    font-size: 83px;
    line-height: 83px;
  }
}

@media screen and (max-width: 1440px) {
  .slogan-wrap {
    width: 300px;
    height: 75px;
    font-size: 75px;
    line-height: 75px;
  }
}

@media screen and (max-width: 1366px) {
  .slogan-wrap {
    width: 285px;
    height: 71px;
    font-size: 71px;
    line-height: 71px;
  }
}

@media screen and (max-width: 1280px) {
  .slogan-wrap {
    width: 268px;
    height: 67px;
    font-size: 67px;
    line-height: 67px;
  }
} */
</style>