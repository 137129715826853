<template>
  <div class="main">
    <div id="title-jp">
      <img src="@/assets/titledector_concept_home.png" alt="" />
      <span v-if="language == 'zh'">ニュース</span>
      <span v-if="language == 'jp'">News</span>
      <span v-if="language == 'eng'">ニュース</span>
      <img src="@/assets/titledector_concept_home.png" alt="" />
    </div>
    <div id="title-zh">
      <span v-if="language == 'zh'">新闻中心</span
      ><span v-if="language == 'jp'" style="font-size: 32px"
        >ニュースリリース</span
      ><span v-if="language == 'eng'">News</span>
    </div>
    <NewsCarousle />
  </div>
</template>

<script>
import NewsCarousle from "./NewsCarousel.vue";
export default {
  name: "NewsCenter",
  components: {
    NewsCarousle,
  },
  props: {
    language: String,
  },
};
</script>

<style scoped lang="scss">
.main {
  width: 100%;
  aspect-ratio: 1920 / 1064;
  background-color: #f0f5f9;
  padding-top: 5.83%;
  padding-bottom: 5.21%;
}
#title-jp {
  margin: 0 auto;
  width: 100px;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #929293;
  line-height: 14px;
}
#title-jp > span {
  margin: 0 4px;
}
#title-zh {
  width: 300px;
  height: 56px;
  font-size: 56px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  line-height: 56px;
  letter-spacing: 4px;
  margin: 1.67% auto 4.79%;
}
</style>