
import request from '@/utils/request'
import admin_request from '@/utils/admin-request'



//获取轮播图
export const getCarousel = () => {
    return request.get('carousel');
}

// 管理员获取轮播图
export const adminGetCarousel = (page, page_size) => {
    return admin_request.get('carousel?Page=' + page + '&PageSize=' + page_size);
}

// 添加轮播图信息
export const addCarousel = ({ img, url }) => {
    return admin_request.post("carousel/info/upload", { img, url })
}

// 删除轮播图
export const deleteCarousel = (id) => {
    return admin_request.delete('carousel?ID=' + id)
}