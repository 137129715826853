<template>
  <div id="news-container">
    <div id="news-group">
      <transition-group :name="transitionName" tag="div" class="item__wrapper">
        <div
          v-for="item in newsList"
          :key="item.id"
          class="item"
          v-bind:style="itemStyle"
          @click="readNews(item.id)"
        >
          <div class="news-text">
            <span class="news-date">{{ _utcToBeijing(item.created_at) }}</span>
            <span class="news-title">{{ item.title }}</span>
            <div class="news-content">{{ item.summary }}</div>
          </div>
          <div class="news-image">
            <img
              :src="item.pic.length != 0 ? item.pic : defalut_image"
              alt=""
            />
          </div>
        </div>
      </transition-group>
    </div>
    <div id="btn-group">
      <div
        id="button-left"
        class="button"
        @click="moveLeft"
        :class="leftButtonStyle"
      >
        <div id="arrow-left"></div>
      </div>
      <div
        id="button-right"
        class="button"
        @click="moveRight"
        :class="rightButtonStyle"
      >
        <div id="arrow-right"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList } from "@/api/news.js";
import { utcToBeijing } from "@/utils/time.js";

export default {
  name: "NewsCarousel",
  data() {
    return {
      position: 0,
      atStartOfArray: true,
      atEndOfArray: false,
      shown: 3,
      itemWidth: 400,
      itemGutter: 20,
      measurement: "px",
      itemHeight: 558,
      left: false,
      canMove: true,
      animationTime: 1000,
      pagination: {
        pageSize: 8,
        current: 1,
        total: 0,
      },
      defalut_image:
        "https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/news-view-default.png",
      newsList: [],
    };
  },
  computed: {
    _utcToBeijing() {
      return (time) => {
        let utc8Time = utcToBeijing(time);
        utc8Time = utc8Time.split(" ")[0];
        return utc8Time;
      };
    },
    transitionName: function () {
      if (this.left) return "carousel-left";
      return "carousel-right";
    },
    carouselWidth: function () {
      return this.shown * (this.itemWidth + this.itemGutter * 2);
    },
    itemStyle: function () {
      return {
        width: this.itemWidth + this.measurement,
        margin: "0 " + this.itemGutter + this.measurement,
        height: this.itemHeight + this.measurement,
      };
    },
    carouselStyle: function () {
      return {
        width: this.carouselWidth + this.measurement,
      };
    },
    leftButtonStyle: function () {
      if (this.atStartOfArray) return "button-disabled";
      return "button-enabled";
    },
    rightButtonStyle: function () {
      if (this.atEndOfArray) return "button-disabled";
      return "button-enabled";
    },
  },

  watch: {
    // 仅在展示的新闻数 < 总新闻数时生效
    position(newVal, oldVal) {
      if (newVal == 0) {
        this.atStartOfArray = true;
        this.atEndOfArray = false;
      } else if (newVal + this.shown == this.pagination.total) {
        this.atEndOfArray = true;
        this.atStartOfArray = false;
      }
    },
  },

  methods: {
    readNews(id) {
      this.$router.push({ name: "News", params: { id } });
    },

    // 获取新闻
    _getNewsList() {
      getNewsList(this.pagination.current, this.pagination.pageSize)
        .then((res) => {
          if (res.data.code === 200) {
            this.newsList = res.data.data.news_list;
            this.pagination.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.msg);
        });
    },

    // 关于news-crad的methods

    moveRight() {
      if (this.pagination.total < 1 || !this.canMove || this.atEndOfArray) {
        return;
      }
      this.left = false;
      const vm = this;
      const removedItem = this.newsList.shift();
      this.position++;
      setTimeout(() => {
        vm.newsList.push(removedItem);
      }, vm.animationTime);
      this.temporarilyDisableMovement();
    },

    moveLeft() {
      if (this.pagination.total < 1 || !this.canMove || this.atStartOfArray) {
        return;
      }
      this.left = true;
      const vm = this;
      const removedItem = this.newsList.pop();
      this.position--;
      setTimeout(() => {
        vm.newsList.unshift(removedItem);
      }, 1);
      this.temporarilyDisableMovement();
    },

    temporarilyDisableMovement() {
      const vm = this;
      this.canMove = false;

      setTimeout(() => {
        vm.canMove = true;
      }, vm.animationTime);
    },
  },

  created() {
    this._getNewsList();
  },
};
</script>

<style scoped lang="scss">
#news-container {
  width: 1280px;
  height: 658px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

#news-group {
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
}

.item {
  &__wrapper {
    position: absolute;
    display: flex;
    left: -20px; // 由于第一个item的左margin有20px，因此需要向左移动20px
  }

  position: relative;
  background-color: rgba(255, 255, 255, 1);
  transition: all 1s;
  backface-visibility: hidden;
  transform: scale(1, 1);
  cursor: pointer;
}

/* moving to the left */
.carousel-left-enter {
  transform: translate(-108%, 0);
}

/*moving to the right */
.carousel-right-leave-to {
  transform: translate(-110%, 0);
}

.carousel-right-leave-active {
  position: absolute;
}

.news-text {
  height: 338px;
  padding: 36px 28px;
  text-align: left;
}
.news-date {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  display: block;
  margin-bottom: 52px;
}
.news-title {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.news-content {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4f4f51;
  line-height: 26px;
  margin-top: 18px;
}

.news-image {
  width: 100%;
  height: 224px;
}
.news-image > img {
  width: 100%;
  height: 100%;
}

#btn-group {
  width: 104px;
  height: 44px;
  display: flex;
  justify-content: space-between;
}

.button {
  &-enabled:hover > #arrow-left {
    width: 15px;
    height: 13px;
    background: no-repeat center
      url("../../assets/Arrow/arrow_previous_active.png");
    background-size: contain;
  }

  &-enabled:hover > #arrow-right {
    width: 15px;
    height: 13px;
    background: no-repeat center url("../../assets/Arrow/arrow_next_active.png");
    background-size: contain;
  }

  &-disabled {
    background-color: rgba(216, 216, 220, 1);
    cursor: not-allowed;
  }

  width: 44px;
  height: 100%;
  border-radius: 100%;
  background-color: rgba(1, 128, 205, 1);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#arrow-left {
  width: 13.64%;
  height: 27.3%;
  background: no-repeat center url("../../assets/Arrow/arrow_previous.png");
  background-size: contain;
}
#arrow-right {
  width: 13.64%;
  height: 27.3%;
  background: no-repeat center url("../../assets/Arrow/arrow_next.png");
  background-size: contain;
}
</style>