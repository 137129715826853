<template>
  <div class="solution-wrap">
    <div class="solution">
      <div class="title-group">
        <div id="title-jp">
          <img
            src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/titledector_concept_home.png"
            alt=""
          />
          <span v-if="language == 'zh'">ソリューションズ</span>
          <span v-if="language == 'jp'">Solutions</span>
          <span v-if="language == 'eng'">ソリューションズ</span>
          <img
            src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/titledector_concept_home.png"
            alt=""
          />
        </div>
        <div id="title-zh">
          <span v-if="language == 'zh'">解决方案</span
          ><span v-if="language == 'jp'">ソリューションズ</span
          ><span v-if="language == 'eng'">Solutions</span>
        </div>
      </div>
      <div class="container">
        <div id="beney-mall" class="solution-block">
          <div id="beney-mall-number" class="number">01</div>
          <div
            id="beney-mall-gif"
            @mouseover="startPlay(gifObjects[0])"
            @mouseleave="stopPlay(gifObjects[0])"
            :style="{
              'background-position-y': ' ' + gifObjects[0].position_y + 'px',
            }"
            class="introduction-gif"
          ></div>
          <div id="beney-mall-title" class="introduction-title">
            <span v-if="language == 'zh'">贝尼商城</span
            ><span v-if="language == 'jp'">Beney Mall</span
            ><span v-if="language == 'eng'">Beney Mall</span>
          </div>
          <div id="beney-mall-content" class="introduction-content">
            <span v-if="language == 'zh'"
              >公司自主研发并运营的社交电商平台（微信小程序・APP・网页版）。产品皆为日本直邮，用户发布种草笔记可以赚积分，社区真实有趣。品牌入驻门槛宽松。</span
            >
            <span v-if="language == 'jp'"
              >自社開発・運営のソーシャルコマースプラットフォーム（Wechatミニプログラム・APP・Web）、掲載商品はすべて日本直送。ユーザーは投稿が価値になる。リアルな口コミ。企業様は事業規模の大小を問わず、簡単にエントリーし、らくらく出店です。</span
            >
            <span v-if="language == 'eng'"
              >Beney Mall is a social commerce platform (Wechat mini program,
              APP, H5) developed and operated by Shanghai Beney E-commerce Co.,
              Ltd. All the goods are delivered directly from Japan. Users can
              get points by sharing real and funny experiences. We set up low
              barries to entry for Japanese companies .
            </span>
          </div>
        </div>
        <div id="beney-agent" class="solution-block">
          <div id="beney-agent-number" class="number">02</div>
          <div
            id="beney-agent-gif"
            @mouseover="startPlay(gifObjects[1])"
            @mouseleave="stopPlay(gifObjects[1])"
            :style="{
              'background-position-y': ' ' + gifObjects[1].position_y + 'px',
            }"
            class="introduction-gif"
          ></div>
          <div id="beney-agent-title" class="introduction-title">
            <span v-if="language == 'zh'">电商代运营</span
            ><span v-if="language == 'jp'">越境EC代行運営</span
            ><span v-if="language == 'eng'">E-commerce Operation</span>
          </div>
          <div id="beney-agent-content" class="introduction-content">
            <span v-if="language == 'zh'"
              >提供中国国内各大电商平台的一站式代运营服务。如：天猫国际、京东国际、抖音小店、小红书商城、快手进口店等。</span
            >
            <span v-if="language == 'jp'"
              >中国大手ECプラットフォームTmall、JD以外、抖音（中国版Tiktok）、Red
              book、快手など のプラットフォームも代行運営をします。</span
            >
            <span v-if="language == 'eng'"
              >E-commerce(Tmall Global, JD Global, Douyin, Red book mall,
              Kuaishou importion , etc.) service operations.</span
            >
          </div>
        </div>
        <div id="beney-trade" class="solution-block">
          <div id="beney-trade-number" class="number">03</div>
          <div
            id="beney-trade-gif"
            @mouseover="startPlay(gifObjects[2])"
            @mouseleave="stopPlay(gifObjects[2])"
            :style="{
              'background-position-y': ' ' + gifObjects[2].position_y + 'px',
            }"
            class="introduction-gif"
          ></div>
          <div id="beney-trade-title" class="introduction-title">
            <span v-if="language == 'zh'">贸易</span
            ><span v-if="language == 'jp'">貿易</span
            ><span v-if="language == 'eng'">Trade Agency</span>
          </div>
          <div id="beney-trade-content" class="introduction-content">
            <span v-if="language == 'zh'">为中日客户提供进出口贸易服务。</span
            ><span v-if="language == 'jp'"
              >日中貿易サービスの提供をします。</span
            ><span v-if="language == 'eng'"
              >Agency of import and export trade between China and Japan.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionBanner",
  props: { language: String },
  data() {
    return {
      gifObjects: [
        {
          position_y: 0,
          height: 174,
          totalFrames: 31,
          tik: 60,
          play: false,
        },

        {
          position_y: 0,
          height: 174,
          play: false,
          totalFrames: 40,
          ref: "savemoney",
          tik: 60,
        },
        {
          position_y: 0,
          height: 174,
          totalFrames: 45,
          play: false,
          tik: 60,
        },
      ],
    };
  },
  methods: {
    startPlay(gifObjects) {
      gifObjects.play = true;
      this._playNext(gifObjects);
    },
    stopPlay(gifObjects) {
      gifObjects.play = false;
      this._playBack(gifObjects);
    },
    _playNext(gifObjects) {
      if (
        gifObjects.play &&
        gifObjects.position_y >
          (gifObjects.totalFrames - 1) * -gifObjects.height
      ) {
        gifObjects.position_y = gifObjects.position_y - gifObjects.height;
        setTimeout(() => {
          this._playNext(gifObjects);
        }, gifObjects.tik);
      }
    },
    _playBack(gifObjects) {
      if (!gifObjects.play && gifObjects.position_y < 0) {
        gifObjects.position_y = gifObjects.position_y + gifObjects.height;
        setTimeout(() => {
          this._playBack(gifObjects);
        }, gifObjects.tik);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.solution {
  &-wrap {
    width: 100%;
    aspect-ratio: 1920 / 894;
    padding-top: 2%;
  }

  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.title-group {
  height: 11.5%;
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#title-jp > span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #929293;
  line-height: 14px;
  margin: 0 8px;
}
#title-zh {
  font-size: 56px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  line-height: 56px;
  letter-spacing: 4px;
}

.container {
  margin: 0 auto;
  width: 1280px; /*** 1920p:1268p */
  height: 422px; /*** 1920p:644p */
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.solution-block {
  width: 390px; /*** 1920p:390p */
}
.number {
  text-align: left;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #242424;
  line-height: 14px;
}
.introduction-gif {
  width: 174px; /*** 1920p:174p */
  min-width: 174px;
  aspect-ratio: 1/1;
  margin-top: 8.2%;
  margin-bottom: 11.28%;
  background-size: 100%;
}
#beney-mall-gif {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/solution_gif_first.png");
}
#beney-agent-gif {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/solution_gif_second.png");
}
#beney-trade-gif {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/solution_gif_third.png");
}
.introduction-title {
  text-align: left;
  height: 24px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #242424;
  line-height: 24px;
  margin-bottom: 7.7%;
}
.introduction-content {
  text-align: left;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4f4f51;
  line-height: 26px;
}
/* 
@media screen and (max-width: 1680px) {
  .number {
    width: 13px;
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 12px;
  }
  .introduction-title {
    width: 84px;
    height: 21px;
    font-size: 21px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 21px;
  }
  .introduction-content {
    width: 341px;
    height: 91px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f51;
    line-height: 23px;
  }
}

@media screen and (max-width: 1600px) {
  .number {
    width: 13px;
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 12px;
  }
  .introduction-title {
    width: 80px;
    height: 20px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 20px;
  }
  .introduction-content {
    width: 325px;
    height: 87px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f51;
    line-height: 22px;
  }
}

@media screen and (max-width: 1440px) {
  .number {
    width: 11px;
    height: 11px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 11px;
  }
  .introduction-title {
    width: 72px;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 18px;
  }
  .introduction-content {
    width: 293px;
    height: 78px;
    font-size: 11px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f51;
    line-height: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .number {
    width: 11px;
    height: 10px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 10px;
  }
  .introduction-title {
    width: 68px;
    height: 17px;
    font-size: 17px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 17px;
  }
  .introduction-content {
    width: 277px;
    height: 74px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f51;
    line-height: 18px;
  }
}

@media screen and (max-width: 1280px) {
  .number {
    width: 10px;
    height: 9px;
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #242424;
    line-height: 9px;
  }
  .introduction-title {
    width: 64px;
    height: 16px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #242424;
    line-height: 16px;
  }
  .introduction-content {
    width: 260px;
    height: 69px;
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f4f51;
    line-height: 17px;
  }
} */
</style>