<template>
  <a-menu
    v-model="current"
    mode="horizontal"
    style="border-bottom-width: 0"
    class="nav-wrapper"
  >
    <a-menu-item key="Home" class="nav" id="home" @click="go('Home')">
      <span v-if="language == 'zh'">首&nbsp;&nbsp;页</span>
      <span v-if="language == 'jp'">ト ッ プ ペ ー ジ</span>
      <span v-if="language == 'eng'">Home</span>
    </a-menu-item>
    <!-- <a-menu-item key="Solutions" class="nav" id="solution">
      解决方案
    </a-menu-item> -->
    <a-menu-item
      key="NewsCenter"
      class="nav"
      id="news"
      @click="go('NewsCenter')"
    >
      <span v-if="language == 'zh'">新闻中心</span>
      <span v-if="language == 'jp'">プレスリリース</span>
      <span v-if="language == 'eng'">News</span>
    </a-menu-item>
    <a-menu-item key="About" class="nav" id="about" @click="go('About')">
      <span v-if="language == 'zh'">关于贝尼丸</span>
      <span v-if="language == 'jp'">ベニーについて</span>
      <span v-if="language == 'eng'">About Beney</span>
    </a-menu-item>
    <a-menu-item key="Contact" class="nav" id="contact" @click="go('Contact')">
      <span v-if="language == 'zh'">业务咨询</span>
      <span v-if="language == 'jp'">お問合せ</span>
      <span v-if="language == 'eng'">Contact</span>
    </a-menu-item>
    <a-menu-item key="Recruit" class="nav" id="recruit" @click="go('Recruit')">
      <span v-if="language == 'zh'">人才招聘</span>
      <span v-if="language == 'jp'">採用情報</span>
      <span v-if="language == 'eng'">Careers</span></a-menu-item
    >
  </a-menu>
</template>

<script>
export default {
  name: "Navigator",
  props: {
    language: String,
  },
  data() {
    return {
      current: [this.$route.name],
    };
  },

  methods: {
    //页面跳转
    go(name) {
      if (this.current[0] != name) {
        this.$router.push({ name });
      }
    },
  },
};
</script>

<style scoped="">
.nav-wrapper {
  width: 634px;
  height: 50px;
  font-family: PingFangSC-Regular;
  overflow-wrap: break-word;
  color: rgba(54, 54, 54, 1);
  font-size: 14px;
  height: 14px;
  line-height: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  padding: 0;
}
</style>