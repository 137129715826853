<template>
  <div class="carousel-container">
    <a-carousel
      autoplay
      :autoplaySpeed="3000"
      :pauseOnHover="false"
      easing
      class="carousel-wrap"
    >
      <div
        v-for="(item, id) in carousels"
        :key="id"
        class="carousel-slide"
        v-bind:style="imgStyle(item)"
      >
        <div class="text-group">
          <div class="big-font-size">
            <span v-if="language == 'zh'">贝尼云（上海）信息科技有限公司</span>
            <span v-if="language == 'jp'">贝尼云（上海）信息科技有限会社</span>
            <span v-if="language == 'eng'"
              >Shanghai Beney E-commerce Co., Ltd.
            </span>
          </div>
          <div class="small-font-size">
            <span v-if="language == 'zh'">落户陆家嘴，连接全世界</span
            ><span v-if="language == 'jp'"
              >陸家嘴に拠点を置き、全世界をつなぐ</span
            ><span v-if="language == 'eng'"
              >Located in Lujiazui Finance & Trade Zone of the world</span
            >
          </div>
        </div>
      </div>
    </a-carousel>
    <vue-canvas-nest :config="config" id="canvas-nest" :el="'#carousel'">
    </vue-canvas-nest>
  </div>
</template>

<script>
import { getCarousel } from "@/api/carousel.js";
export default {
  name: "Carousel",

  data() {
    return {
      config: {
        color: "255,255,255",
        opacity: 1,
        zIndex: 1,
        count: 49,
      },
      carousels: [],
    };
  },
  props: {
    language: String,
  },
  methods: {
    _getCarousel() {
      getCarousel()
        .then((res) => {
          if (res.data.code === 200) {
            this.carousels = res.data.data.carousels;
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.msg);
        });
    },
  },
  created() {
    this._getCarousel();
  },
  computed: {
    imgStyle() {
      return function (item) {
        return {
          background: "no-repeat center" + " url('" + item.img + "')",
          "aspect-ratio": "1920 / 830",
          "overflow ": "hidden",
        };
      };
    },
  },
};
</script>

<style scoped >
.carousel-wrap {
  width: 100%;
  aspect-ratio: 1920 / 830;
  background-size: 100%;
  color: white;
}
.carousel-slide {
  width: 100%;
  height: 100%;
}

.ant-carousel >>> .slick-dots-bottom {
  height: 15px;
  bottom: 0;
  margin-bottom: 20px;
}

.ant-carousel >>> .slick-dots > li > button {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
  margin: 0 10px;
}
.text-group {
  margin-top: 10%;
}

.big-font-size {
  font-size: 40px;
  margin-bottom: 18px;
}
.small-font-size {
  font-size: 16px;
}
#canvas-nest {
  height: 100%;
  width: 100%;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>;
