<template>
  <div class="sns-group">
    <div id="douyin">
      <div id="douyin-code"></div>
    </div>
    <div id="weixin">
      <div id="weixin-code"></div>
    </div>
    <div id="weibo" @click="open('https://weibo.com/u/7743267850')"></div>
    <div
      id="xiaohongshu"
      @click="
        open(
          'https://www.xiaohongshu.com/user/profile/621ee4980000000010005b5e'
        )
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: "SNS",
  methods: {
    open(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.sns-group {
  width: 121px;
  height: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sns-group > div {
  width: 18px;
  height: 18px;
}
#douyin {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/douyin.png");
  background-size: contain;

  &:hover {
    background: no-repeat
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/douyin_active.png");
    background-size: contain;
    cursor: pointer;
  }

  &:hover > &-code {
    width: 108px;
    height: 116px;
    background: no-repeat center
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/beney_douyin.png");
    background-size: 100%;
    position: relative;
    right: 48px;
    top: 25px;
  }
}

#weixin {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/weixin.png");
  background-size: contain;

  &:hover {
    background: no-repeat
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/weixin_active.png");
    background-size: contain;
    cursor: pointer;
  }

  &:hover > &-code {
    width: 108px;
    height: 116px;
    background: no-repeat center
      url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/beney_wechat.png");
    background-size: 100%;
    position: relative;
    right: 48px;
    top: 25px;
  }
}
#weibo {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/weibo.png");
  background-size: contain;
}
#weibo:hover {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/weibo_active.png");
  background-size: contain;
  cursor: pointer;
}
#xiaohongshu {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/xiaohongshu.png");
  background-size: contain;
}
#xiaohongshu:hover {
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/SNS/xiaohongshu_active.png");
  background-size: contain;
  cursor: pointer;
}
</style>  