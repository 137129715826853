<template>
  <div class="language flex-row justify-between">
    <a-dropdown>
      <a
        v-if="language == 'zh'"
        class="ant-dropdown-link"
        @click="(e) => e.preventDefault()"
      >
        简体中文 <a-icon type="caret-down" class="icon" />
      </a>
      <a
        v-if="language == 'jp'"
        class="ant-dropdown-link"
        @click="(e) => e.preventDefault()"
      >
        日本語 <a-icon type="caret-down" class="icon" />
      </a>
      <a
        v-if="language == 'eng'"
        class="ant-dropdown-link"
        @click="(e) => e.preventDefault()"
      >
        English <a-icon type="caret-down" class="icon" />
      </a>
      <a-menu slot="overlay">
        <a-menu-item>
          <a v-if="language == 'zh'" href="javascript:;" @click="select('zh')"
            >中文</a
          >
          <a v-if="language == 'jp'" href="javascript:;" @click="select('zh')"
            >簡体字中国語</a
          >
          <a v-if="language == 'eng'" href="javascript:;" @click="select('zh')"
            >Chinese</a
          >
        </a-menu-item>
        <a-menu-item>
          <a v-if="language == 'zh'" href="javascript:;" @click="select('eng')"
            >英文</a
          >
          <a v-if="language == 'jp'" href="javascript:;" @click="select('eng')"
            >英語</a
          >
          <a v-if="language == 'eng'" href="javascript:;" @click="select('eng')"
            >English</a
          >
        </a-menu-item>
        <a-menu-item>
          <a v-if="language == 'zh'" href="javascript:;" @click="select('jp')"
            >日文</a
          >
          <a v-if="language == 'jp'" href="javascript:;" @click="select('jp')"
            >日本語</a
          >
          <a v-if="language == 'eng'" href="javascript:;" @click="select('jp')"
            >Japanese</a
          >
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>

<script>
import storage from "@/utils/stored-data.js";
export default {
  name: "Language",
  props: {
     language: String,
  },
  methods: {
    select(language) {
      storage.set("language", language, 7 * 24 * 60);
      this.changeLanguage(language);
    },
    changeLanguage(language) {
      this.$bus.$emit("language", language);
    },
  },

};
</script>

<style scoped lang="scss">
.language {
  display: inline;
}

.ant-dropdown-link {
  color: black;

  & > .icon {
    margin-left: 8px;
  }
}

.msg {
  color: rgba(94, 94, 96, 1);
  font-size: 14px;
  white-space: nowrap;
  margin-right: 8px;
}

.arrow {
  display: inline;
}
</style>