<template>
  <div class="corpBrand" ref="corpBrand">
    <div class="container">
      <div class="title-group">
        <div id="title-jp-wrap">
          <img
            src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/titledector_concept_home.png"
            alt=""
          />
          <span id="title-jp" class="small-font-size">ブランド</span>
          <img
            src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/titledector_concept_home.png"
            alt=""
          />
        </div>

        <div id="title-ch-wrap">
          <div id="title-ch" class="big-font-size">
            <span v-if="language == 'zh'">合作品牌</span>
            <span v-if="language == 'jp'">出店ブランド</span>
            <span v-if="language == 'eng'">Brand List</span>
          </div>
        </div>
      </div>

      <div id="brand-container-wrap">
        <div id="curve-group">
          <svg>
            <defs>
              <linearGradient
                id="linear-left"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stop-color="rgba(226, 238, 255, 1)" />
                <stop offset="100%" stop-color="rgba(176, 195, 222, 0.55) " />
              </linearGradient>
            </defs>
          </svg>
          <svg>
            <defs>
              <linearGradient
                id="linear-right"
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset="0%" stop-color="rgba(176, 195, 222, 0.55)" />
                <stop offset="100%" stop-color="rgba(226, 238, 255, 1) " />
              </linearGradient>
            </defs>
          </svg>
          <svg
            v-for="(item, index) in leftLines"
            :key="'left_' + index"
            :width="item.width"
            :height="item.height"
            :style="{ left: item.left, top: item.top }"
            :viewBox="item.viewBox"
            class="curve-line"
          >
            <path
              :d="item.d"
              stroke="url(#linear-left)"
              stroke-opacity="1"
              stroke-width="3"
              fill-rule="evenodd"
            />
          </svg>
          <svg
            v-for="(item, index) in rightLines"
            :key="'right' + index"
            :width="item.width"
            :height="item.height"
            :style="{ right: item.right, top: item.top }"
            :viewBox="item.viewBox"
            class="curve-line"
          >
            <path
              :d="item.d"
              stroke="url(#linear-right)"
              stroke-opacity="1"
              stroke-width="3"
              fill-rule="evenodd"
            />
          </svg>
        </div>
        <div id="brand-container">
          <div id="brand-group-1" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/shiseido_corporation_home.png"
              alt=""
              id="brand-num-1"
            />
            <CorpCircle id="brand-num-1-circle" class="big-position-circle" />
          </div>
          <div id="brand-group-2" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/beney_corporation_home.png"
              alt=""
              id="brand-num-2"
            />
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/position_circle_corporation_home.png"
              alt=""
              id="brand-num-2-circle"
            />
          </div>
          <div id="brand-group-3" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/seasonal_corporation_home.png"
              alt=""
              id="brand-num-3"
            />
            <CorpCircle id="brand-num-3-circle" class="small-position-circle" />
          </div>
          <div id="brand-group-4" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/kobayashi_corporation_home.png"
              alt=""
              id="brand-num-4"
            />
            <CorpCircle id="brand-num-4-circle" class="small-position-circle" />
          </div>
          <div id="brand-group-5" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/uno_corporation_home.png"
              alt=""
              id="brand-num-5"
            />
            <CorpCircle id="brand-num-5-circle" class="small-position-circle" />
          </div>
          <div id="brand-group-6" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/sk_corporation_home.png"
              alt=""
              id="brand-num-6"
            />
            <CorpCircle id="brand-num-6-circle" class="small-position-circle" />
          </div>
          <div id="brand-group-7" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/takashimaya_corporation_home.png"
              alt=""
              id="brand-num-7"
            />
            <CorpCircle id="brand-num-7-circle" class="small-position-circle" />
          </div>
          <div id="brand-group-8" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/kao_corporation_home.png"
              alt=""
              id="brand-num-8"
            />
            <CorpCircle id="brand-num-8-circle" class="big-position-circle" />
          </div>
          <div id="brand-group-9" class="brand-group">
            <img
              src="https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/pigeon_corporation_home.png"
              alt=""
              id="brand-num-9"
            />
            <CorpCircle id="brand-num-9-circle" class="big-position-circle" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CorpCircle from "./CorpCircle.vue";
export default {
  name: "CorpBrand",
  components: {
    CorpCircle,
  },

  data() {
    return {
      leftLines: [],
      rightLines: [],
      unusedLeftLines: [
        {
          width: 257,
          height: 31,
          left: 330,
          top: 205,
          viewBox: "0 0 257 31",
          d: "M1 8.00001C63.4 -11.6 197 14.5 256 30",
        },

        {
          width: 526,
          height: 210,
          left: 65,
          top: 160,
          viewBox: "0 0 526 210",
          d: "M1.5 209.5C-4.1 -78.5 348.167 -6.16666 525 66",
        },
        {
          width: 122,
          height: 133,
          left: 465,
          top: 232,
          viewBox: "0 0 122 133",
          d: "M1 132C53.8 40 103.333 6 121.5 0.5",
        },
        {
          width: 306,
          height: 206,
          left: 281,
          top: 229,
          viewBox: "0 0 306 206",
          d: "M0.5 205C81.7 52.6 237.333 5.5 305 1",
        },
        {
          width: 44,
          height: 308,
          left: 547,
          top: 233,
          viewBox: "0 0 44 308",
          d: "M30.5 307C-28.7 133.4 14.1667 30.6667 43 1",
        },
      ],
      unusedRightLines: [
        {
          width: 383,
          height: 77,
          right: 80,
          top: 230,
          viewBox: "0 0 383 77",
          d: "M382 76C385.6 12.4 129.5 -0.499997 1 1",
        },
        {
          width: 222,
          height: 43,
          right: 239,
          top: 187,
          viewBox: "0 0 222 43",
          d: "M221 14.5C124.6 -19.5 33.8333 18.6667 0.5 42",
        },
        {
          width: 188,
          height: 201,
          right: 276,
          top: 237,
          viewBox: "0 0 188 201",
          d: "M187 200C164.2 56.4 53.5 7.5 1 1",
        },
      ],
    };
  },
  props: {
    scrollTopNum: {
      type: Number,
      required: true,
    },
    language: String,
  },
  watch: {
    scrollTopNum: function () {
      let offsetTop = this.$refs.corpBrand.offsetTop;
      // 120为header高度
      if (this.scrollTopNum > offsetTop - 120) {
        this.leftLines = this.unusedLeftLines;
        this.rightLines = this.unusedRightLines;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.corpBrand {
  aspect-ratio: 1920 / 950;
  width: 100%;
  // min-height: 720px;
  background: no-repeat
    url("https://beneyhome-shanghai.oss-cn-shanghai.aliyuncs.com/static/CorpBrands/logobg_corporation_home.png");
  background-size: 100%;
  position: relative;
}
#title-jp {
  margin: 0 4px;
}

.container {
  width: 1054px; /* 1920px:55% */
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
#curve-group {
  width: 1054px; /* 1920px:55% */
  height: 100%;
  position: absolute;
}
.small-font-size {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #e1ecf9;
}
#title-ch-wrap {
  margin: 1.667% auto 0;
}
.big-font-size {
  font-size: 56px;
  letter-spacing: 4px;
  line-height: 1;
  color: #ffffff;
}
#brand-container-wrap {
  width: 1054px; /* 1920px:1054px */
  height: 557px; /* 1920px:736px */
  position: relative;
}
#brand-container {
  width: 1023px;
  aspect-ratio: 1023 / 557;
  text-align: left;
  position: relative;
}
.brand-group > img {
  width: 100%;
}
#brand-group-1 {
  width: 146px;
  aspect-ratio: 146 / 210;
  position: absolute;
  left: 260px;
  top: 23px;
  /* border: 1px solid red; */
}
.small-position-circle {
  width: 40px;
}
.big-position-circle {
  width: 52px;
}
#brand-num-1-circle {
  width: 52px;
  margin-left: 45px;
  margin-top: 12px;
}
#brand-group-2 {
  width: 176px;
  aspect-ratio: 176 / 265;
  position: absolute;
  left: 504px;
  top: 0;
}
#brand-num-2-circle {
  width: 97px;
  margin: 14px 39px 0;
}
#brand-group-3 {
  width: 146px;
  aspect-ratio: 146 / 206;
  position: absolute;
  right: 142px;
  top: 12px;
}
#brand-num-3-circle {
  width: 40px;
  margin: 18px auto 0;
}
#brand-group-4 {
  width: 146px;
  aspect-ratio: 146 / 205;
  position: absolute;
  top: 186px;
  /* border: 1px solid red; */
}
#brand-num-4-circle {
  width: 40px;
  margin: 17px 0 0 48px;
}
#brand-group-5 {
  width: 122px;
  aspect-ratio: 122 / 175;
  position: absolute;
  top: 209px;
  left: 405px;
  /* border: 1px solid red; */
}
#brand-num-5-circle {
  width: 40px;
  margin: 13px 0 0 39px;
}
#brand-group-6 {
  width: 97px;
  height: 151px;
  position: absolute;
  top: 173px;
  right: 0px;
  /* border: 1px solid red; */
}
#brand-num-6-circle {
  width: 40px;
  margin: 13px auto 0;
}
#brand-group-7 {
  width: 122px;
  aspect-ratio: 122 /174;
  position: absolute;
  top: 276px;
  left: 224px;
  /* border: 1px solid red; */
}
#brand-num-7-circle {
  width: 40px;
  margin-left: 38px;
  margin-top: 11px;
}
#brand-group-8 {
  width: 146px;
  aspect-ratio: 146 / 201;
  position: absolute;
  bottom: 0px;
  right: 368px;
  /* border: 1px solid red; */
}
#brand-num-8-circle {
  width: 35.4%;
  margin-left: 30.13%;
  margin-top: 2.05%;
}
#brand-group-9 {
  width: 146px;
  aspect-ratio: 146 / 201;
  position: absolute;
  top: 253px;
  right: 170px;
  /* border: 1px solid red; */
}
#brand-num-9-circle {
  width: 52px;
  margin-left: 44px;
  margin-top: 3px;
}
.curve-line {
  position: absolute;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  stroke-width: 3px;
  animation: dash 4s ease 1s;
  animation-fill-mode: forwards;
  fill: none;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    opacity: 0.6;
  }
  to {
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

@media screen and (max-width: 1680px) {
  .small-font-size {
    font-size: 12px;
  }
  .big-font-size {
    font-size: 49px;
  }
}
@media screen and (max-width: 1600px) {
  .small-font-size {
    font-size: 12px;
  }
  .big-font-size {
    font-size: 47px;
  }
}
@media screen and (max-width: 1440px) {
  .small-font-size {
    font-size: 11px;
  }
  .big-font-size {
    font-size: 42px;
  }
}
@media screen and (max-width: 1366px) {
  .small-font-size {
    font-size: 10px;
  }
  .big-font-size {
    font-size: 40px;
  }
}
@media screen and (max-width: 1280px) {
  .small-font-size {
    font-size: 9px;
  }
  .big-font-size {
    font-size: 37px;
    letter-spacing: 3px;
  }
}
</style>