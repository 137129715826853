import axios from 'axios';
import config from "../config"
// import storage from "@/utils/stored-data.js"

const service = axios.create({
    baseURL: config.url,
    timeout: 5000,
    headers: {}
})

// 请求拦截 设置token
// service.interceptors.request.use((config) => {
//     Object.assign(config.headers, { Authorization: `Bearer ${storage.get('admin')}` });
//     return config
// }); (error) => {
//     return Promise.reject(error)
// }

export default service;