import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import adminRoutes from "./admin.js";
import storage from "@/utils/stored-data.js";


Vue.use(VueRouter)

// 解决重复点击报错的问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue')
  },
  {
    path: '/news',
    name: 'NewsCenter',
    component: () => import('@/views/NewsCenter.vue'),
  },
  {
    path: '/recruit',
    name: 'Recruit',
    component: () => import('@/views/Recruitment.vue')
  },

  {
    path: '/news/:id',
    name: 'News',
    component: () => import('@/views/News.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy.vue'),
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/views/Agreement.vue'),
  },
  {
    path: '/license',
    name: 'License',
    component: () => import('@/views/License.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
]


const routes = baseRoutes.concat(adminRoutes);

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  // 是否需要登录
  if (to.meta.admin) {
    // 是否存在token
    if (storage.get('admin')) {
      next();
    } else {
      router.push({ name: 'AdminLogin' })
    }
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  // 用户的语言设置
  if (to.name == "Home") {
    // 如果用户前往首页，则不需要判断用户所使用的语言,因为会在首页获取
    next()
  }
  if (storage.get('language')) {
    next()
  } else {
    // language
    router.push({ name: "Home" })
  }
})



export default router

