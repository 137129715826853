import admin_request from '@/utils/admin-request'
import request from '@/utils/request'

// 获取新闻列表
export const getNewsList = (page, page_size) => {
    return request.get('news-list?Page=' + page + '&PageSize=' + page_size);
}


// 管理员获取新闻列表
export const adminGetNewsList = (page, page_size) => {
    return admin_request.get('news-list?Page=' + page + '&PageSize=' + page_size);
}

// 获取单条新闻
export const getNews = (id) => {
    return request.get('news?ID=' + id)
}

// 管理员获取单条新闻
export const adminGetNews = (id) => {
    return admin_request.get('news?ID=' + id)
}


// 添加新闻
export const addNews = ({ title, summary, body, pic }) => {
    return admin_request.post('news', { title, summary, body, pic })
}

// 上传图片
export const uploadNewsImg = (formdata) => {
    return admin_request.post('news/img', formdata)
}

// 删除新闻
export const deleteNews = (id) => {
    return admin_request.delete('news?ID=' + id)
}

// 修改新闻
export const modifyNews = ({ id, title, body, summary, pic }) => {
    return admin_request.put('news', { id, title, body, summary, pic })
}

