import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vueCanvasNest from '../node_modules/vue-canvas-nest/src/vueCanvasNest.vue'
import config from './config';
import {
  Button,
  Dropdown,
  Icon,
  Radio,
  Divider,
  Menu,
  Modal,
  Input,
  Select,
  FormModel,
  Checkbox,
  message,
  Collapse,
  Table,
  Carousel,
  Popconfirm,
  Tag,
  Upload,
  DatePicker,
} from "ant-design-vue";
Vue.use(Menu);
Vue.use(FormModel);
Vue.use(Radio);
Vue.use(Collapse);




Vue.prototype.$success = Modal.success;
Vue.prototype.$config = config;
Vue.prototype.$message = message;

Vue.component(vueCanvasNest.name, vueCanvasNest);
Vue.component(Button.name, Button);
Vue.component(Dropdown.name, Dropdown);
Vue.component(Icon.name, Icon);
Vue.component(Divider.name, Divider);
Vue.component(Input.name, Input);
Vue.component(Checkbox.name, Checkbox);
Vue.component(Carousel.name, Carousel);
Vue.component(Table.name, Table);
Vue.component(Modal.name, Modal);
Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Tag.name, Tag);
Vue.component(Upload.name, Upload);
Vue.component(Upload.Dragger.name, Upload.Dragger);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Select.name, Select);
Vue.component(Select.Option.name, Select.Option);



Vue.config.productionTip = false


new Vue({
  router,
  render: h => h(App),
  beforeCreate() {
    Vue.prototype.$bus = this
  }
}).$mount('#app')
